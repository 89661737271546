import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Sidebar from "../components/falseceilingsidebar"
import Select from 'react-select';
import { Container, Row, Col } from "react-bootstrap"
// import {Carousel} from 'react-bootstrap'
import GypsumCost from "./gypsumcost"

// import slide01 from "../images/FC1.jpg"
// import slide02 from "../images/FC2.jpg"
// import slide03 from "../images/FC3.jpg"

import FCCalculator from "./falseceilingCalculatorEstimates"
import FCAreaCalculator from "./fcareaCalculator"
import FCSimpleCalculator from "./falseCeilingSimpleCalculator"

const FalseCeiling = () => (
  <Layout>
   <SEO
        title="फाल्स सीलिंग"
        keywords={[
          "tags",
          "Pramri Interiors tages",
          "false ceiling",
          "Tips for false ceiling",
          "Interior Design",
          "Interior Design planning",
          "Gypsum vs POP",
          "Different types of False Ceiling",
          "Modular Kitchen",
          "Kitchen Design",
          "House Painting",
          "Kitchen Laminates",
          "PVC Laminates",
          "Laminates Selection",
          "Small house Design",
          "Small master bedroom design",
          "Master Bedroom design",
          "Woodwork",
          "Home Interiors",
          "tags",
          "Pramri Interiors tags",
          "false ceiling in hindi",
          "Tips for false ceiling in hindi",
          "Interior Design in hindi",
          "Interior Design planning in hindi",
          "Gypsum vs POP in hindi ",
          "Different types of False Ceiling in hindi",
          "Modular Kitchen in hindi",
          "Kitchen Design in hindi ",
          "House Painting in hindi",
          "Kitchen Laminates in hindi",
          "PVC Laminates in hindi",
          "Laminates Selection in hindi",
          "Small house Design in hindi",
          "Small master bedroom design in hindi",
          "Master Bedroom design in hindi",
          "Woodwork in hindi",
          "Home Interiors in hindi"  
        ]}
      />
    <Container>
      <h1>False Ceiling</h1>
      <Row>
        <Col md="9">
       

        

        </Col>
        <Col md="3">
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.4)",
            }}
          >
            <Sidebar />
          </div>
        </Col>
      </Row>
     
    </Container>
  </Layout>
)

export default FalseCeiling
