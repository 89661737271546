import React, { useState } from "react"
import Select from "react-select"
import ReactTooltip from "react-tooltip"

import {
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap"

class FalseCeilingCalculatorEstimates extends React.Component {
  constructor() {
    super()
    this.state = {
      measurement: "",
      noOfLightPoints: "",
      noOfLightPointsBig: "",
      total: "",
      noOfLightPointsSmall: 0,
      noOfSwitchBoard: "",
      material: "",
      smallLedPrice: "",
      bigLedPrice: "",
    }
  }

  handlemeasurement = event => {
    this.setState({
      measurement: event.target.value,
    })
  }

  handleMaterial = event => {
    this.setState({
      material: event.target.value,
    })
  }

  handleLightPoints = event => {
    this.setState({
      noOfLightPoints: event.target.value,
    })
  }

  handleNoOfSmallLights = event => {
    this.setState({
      noOfLightPointsSmall: event.target.value,
    })
  }

  handleNoOfBigLights = event => {
    this.setState({
      noOfLightPointsBig: event.target.value,
    })
  }

  handleSmallLedPrice = event => {
    this.setState({
      smallLedPrice: event.target.value,
    })
  }
  handleBigLedPrice = event => {
    this.setState({
      bigLedPrice: event.target.value,
    })
  }

  handleNoOfSwitchBoard = event => {
    this.setState({
      noOfSwitchBoard: event.target.value,
    })
  }

  exe = event => {
    this.setState({
      total:
        parseInt(this.state.measurement) * parseInt(this.state.material) +
        parseInt(this.state.noOfLightPointsSmall) *
          parseInt(this.state.smallLedPrice) +
        parseInt(this.state.noOfLightPointsBig) *
          parseInt(this.state.bigLedPrice) +
        parseInt(this.state.noOfSwitchBoard),
    })
    event.preventDefault()
  }

  render() {
    return (
      <div>
        <Form className="text-center" onSubmit={this.exe}>
          <FormGroup row>
            <Label for="id_countertop_material" sm={2}>
              Measurement
            </Label>
            <Col sm={10}>
              <Input
                type="text"
                value={this.state.measurement}
                onChange={this.handlemeasurement}
                placeholder="False Ceiling area in SFT"
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="id_cabinet_material" sm={2}>
              Material
            </Label>
            <Col sm={10}>
              <select
                className="form-control mb-3"
                value={this.state.handleMaterial}
                onChange={this.handleMaterial}
              >
                <option selected hidden disabled>
                  Select False Ceiling Material
                </option>
                <option value="70">Gypsum</option>
                <option value="70">POP</option>
                <option value="90">PVC</option>
              </select>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="id_countertop_material" sm={2}>
              No of Small LED Lights
            </Label>
            <Col sm={10}>
              <Input
                type="text"
                value={this.state.noOfLightPointsSmall}
                onChange={this.handleNoOfSmallLights}
                placeholder="No of lights below 6 watts"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="id_countertop_material" sm={2}>
              Small LED Price
            </Label>
            <Col sm={10}>
              <Input
                type="text"
                value={this.state.smallLedPrice}
                onChange={this.handleSmallLedPrice}
                placeholder="Price per small LED"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="id_countertop_material" sm={2}>
              No of Big LED Lights
            </Label>
            <Col sm={10}>
              <Input
                type="text"
                value={this.state.noOfLightPointsBig}
                onChange={this.handleNoOfBigLights}
                placeholder="No of lights above 6 watts"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="id_countertop_material" sm={2}>
              Big LED Price
            </Label>
            <Col sm={10}>
              <Input
                type="text"
                value={this.state.bigLedPrice}
                onChange={this.handleBigLedPrice}
                placeholder="Price per Big LED"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="id_shutter_material" sm={2}>
              New Switchboards
            </Label>
            <Col sm={10}>
              <select
                className="form-control mb-3"
                value={this.state.wiring}
                onChange={this.handleNoOfSwitchBoard}
              >
                <option selected hidden disabled>
                  Wiring for Switch Board
                </option>
                <option value="2000">0</option>
                <option value="4000">1</option>
                <option value="6000">2</option>
              </select>
            </Col>
          </FormGroup>

          <FormGroup check row>
            <Col sm={{ size: 2, offset: 2 }}>
              <button className="btn btn-outline-success"> Total Price</button>
            </Col>
          </FormGroup>
        </Form>
        {this.state.total}
      </div>
    )
  }
}

export default FalseCeilingCalculatorEstimates
