import React from "react"
import ReactTooltip from 'react-tooltip';

import {
  Col,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap"

class FCSimpleCalculator extends React.Component {
  constructor() {
    super()
    this.state = {
      roomlength: "",
      roomwidth: "",
      sidetraylength: "",
      sidetrayvertical: "",
      total: "",
    }
    // this.handleChange = this.handleChange.bind(this);
    // this.handleCabinetMaterial=this.handleCabinetMaterial.bind(this);
    
  }

  handleLength = event => {
    this.setState({
      roomlength: event.target.value,
    })
  }

  handleWidth = event => {
    
    this.setState({
      roomwidth: event.target.value,
    })
  }

  handleTrayLength = event => {
    this.setState({
      sidetraylength: event.target.value
    })
  }

  handleSideTrayVertialLength = event => {
    this.setState({
      sidetrayvertical: event.target.value
    })
  }

  exe = event => {
    this.setState({
      total:
        (parseInt(this.state.roomlength) *
        parseInt(this.state.roomwidth)) + 
        parseInt(this.state.sidetraylength) + 
        parseInt(this.state.sidetrayvertical)
    })
    event.preventDefault()
  }

  render() {
    return (
      <div>
        <Form className="text-center" onSubmit={this.exe}>
          <FormGroup row>
            <Label for="id_countertop_material" sm={2}>
              Room Length
            </Label>
            <Col sm={4}>
              <Input
                type="text"
                value={this.state.roomlength}
                onChange={this.handleLength}
                placeholder="False Ceiling length in SFT"
              />
            </Col>
          </FormGroup>


          <FormGroup row>
            <Label for="id_countertop_material" sm={2}>
              Room width
            </Label>
            <Col sm={4}>
              <Input
                type="text"
                value={this.state.roomwidth}
                onChange={this.handleWidth}
                placeholder="Room width in sft"
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="id_countertop_material" sm={2}>
              Side Tray Length
            </Label>
            <p data-tip="Tray length ka matlab jo side me ek vertical hota hai"></p>
              <Col sm={4}>
              <Input
                type="text"
                value={this.state.sidetraylength}
                onChange={this.handleTrayLength}
                placeholder="Total tray length in sft" 
              />
              
            </Col>
            <ReactTooltip />
          </FormGroup>

          <FormGroup row>
            <Label for="id_countertop_material" sm={2}>
              Side Tray Vertical
            </Label>
            <p data-tip="Tray length ka matlab jo side me ek vertical hota hai"></p>
              <Col sm={4}>
              <Input
                type="text"
                value={this.state.sidetrayvertical}
                onChange={this.handleSideTrayVertialLength}
                placeholder="Total tray length in sft" 
              />
              
            </Col>
            <ReactTooltip />
          </FormGroup>

           <FormGroup check row>
            <Col sm={{ size: 2, offset: 2 }}>
              <button className="btn btn-outline-success"> Total Area</button>
            
            </Col>
          </FormGroup>

          {/* Need to implement Clear button functionality */}

          {/* <FormGroup check row>
            <Col sm={{ size: 10, offset: 2 }}>
              <button className="btn btn-outline-success" type="submit" onClick={handleSubmit}> Clear</button>
            
            </Col>
          </FormGroup> */}

        </Form>
        {this.state.total} Sft
      </div>
    )
  }
}

// function handleSubmit(e) {
//   e.preventDefault();
//   // clearing the values
//   roomlength.setState("")
  
// }

export default FCSimpleCalculator

