import React from "react"
import { Card, CardTitle, CardBody, Form, FormGroup, CardText } from "reactstrap"
import { graphql } from "gatsby"
import { Link } from "gatsby"

//This is used for horizontal line
const ColoredLine = ({ color }) => (
  <hr
      style={{
          color: color,
          backgroundColor: color,
          height: 3
      }}
  />
);

const ColoredThinLine = ({ color }) => (
  <hr
      style={{
          color: color,
          backgroundColor: color,
          height: 1
      }}
  />
);
const Sidebar = () => (
  <div>
    <Card>
      <CardBody>
      <CardTitle className="text-center text-uppercase mb-3">
          <b>False Ceiling budget Calculator</b>
        </CardTitle>

        <Form className="text-center">
          <ColoredThinLine/>
          <FormGroup>
          <Link to="/falseceilingcostcalculator" activeStyle={{ color: "red" }}>Quick False Ceiling Calculator</Link>
          </FormGroup>
          <ColoredThinLine/>

          <FormGroup>
          <Link to="/falseceilingcalculator" activeStyle={{ color: "red" }}>Advance False Ceiling Calculator</Link>
          </FormGroup>
          <ColoredLine color="red" />
          <CardTitle className="text-center text-uppercase mb-3">
          <b>Must Watch</b>
        </CardTitle>
          <FormGroup>
          <Link to="https://youtu.be/KMDAhgMctn4" activeStyle={{ color: "red" }}>False Ceiling का Measurement कैसे करते हैं ?</Link>
          </FormGroup>
          <ColoredThinLine/>
          <FormGroup>
          <Link to="https://youtu.be/Crra1NvJ1Xk" activeStyle={{ color: "red" }}>False Ceiling Quick Budget Calculator</Link>
          </FormGroup>
        </Form>
        
        <ColoredLine color="red" />
        <Form className="text-center" color='gray'></Form>

        <CardTitle className="text-center text-uppercase mb-3">
          <b>Recommended</b>
        </CardTitle>
        
        <Form className="text-center">
          {/* <FormGroup>
            <Input
              type="email"
              name="email"
              placeholder="Your email address.."
            />
          </FormGroup>
          <button className="btn btn-outline-success text=uppercase">
            Subscribe
          </button> */}

          <FormGroup>
          <Link to="/or-pop-gypsum-vs-pop" activeStyle={{ color: "red" }}>जिप्सम या POP ? कौन सा अच्छा ऑप्शन है ?</Link>
          </FormGroup>
          <ColoredThinLine/>

          <FormGroup>
          <Link to="/different-types-of-false-ceiling" activeStyle={{ color: "red" }}>फाल्स सीलिंग कितने तरीके से की जाती है ? ?</Link>
          </FormGroup>
          <ColoredThinLine/>

          <FormGroup>
          <Link to="/measurement-how-to-measure-false-ceiling" activeStyle={{ color: "red" }}>फाल्स सीलिंग का measurement कैसे करते हैं ?</Link>
          </FormGroup>
          <ColoredThinLine/>

          



          
          
        </Form>
        
        <ColoredLine color="red" />
        <Form className="text-center" color='gray'>

        <CardTitle className="text-center text-uppercase mb-3">
          <b>Video reference links</b>
        </CardTitle>
         {/* <FormGroup>
          <div className="embed-responsive embed-responsive-16by9">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/ne_ldHj1BNU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

        </div>
        </FormGroup>

        <ColoredThinLine/>
        <FormGroup>
        <div className="embed-responsive embed-responsive-16by9">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/J67m8QGWYRA" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

        </div>
        </FormGroup> */}
          <FormGroup>
          <Link to="https://youtu.be/ne_ldHj1BNU" activeStyle={{ color: "red" }}>False ceiling कराने से पहले ये वीडियो जरूर देखे</Link>
          </FormGroup>
          <ColoredThinLine/>
          <FormGroup>
          <Link to="https://youtu.be/J67m8QGWYRA" activeStyle={{ color: "red" }}>Gyspum vs POP False ceiling </Link>
          </FormGroup>
          <ColoredThinLine/>
          
        </Form>
        {/* <Link to="/tags" activeStyle={{ color: "red" }}>Tags </Link> */}
        <tagPost/>
        <tagPage/>
        <ColoredLine color="red" />

        <CardTitle className="text-center text-uppercase mb-3">
        <b>Kitchen Calculator</b>
        </CardTitle>
        <button className="btn btn-outline-success text=uppercase" >
        Kitchen
          </button>
        
      </CardBody>
    </Card>
  </div>
)

const sidebarQuery = graphql`
  query sidebarQuery {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 3
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

export default Sidebar
